import React, { useState, lazy, Suspense } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { Drawer, AppBar, Toolbar, List, Typography, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import { Menu, ChevronLeft, ChevronRight, FavoriteBorder, Favorite, MonetizationOnOutlined, MonetizationOn, Instagram } from '@material-ui/icons';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import router from "../../routes/router";
import logo from '../../images/logo.jpg'
import SignOut from '../signOut/SignOut';
import CustomDialog from '../customDialog/CustomDialog';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    side: {
      position: 'relative'
    },
    logo: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      width: 50,
      borderRadius: '50%',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      overflow: 'hidden'
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText
    }
  }),
);

const toolList = [
  {
    title: 'Bound Users',
    icon: FavoriteBorder,
    activeIcon: Favorite,
    path: '/'
  },
  {
    title: 'Instagram Users',
    icon: Instagram,
    activeIcon: Instagram,
    path: '/instagram-users'
  },
  {
    title: 'Update Coins',
    icon: MonetizationOnOutlined,
    activeIcon: MonetizationOn,
    path: '/coins'
  }
]

const DrawerMain = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [currentActivedToolIndex, setActivedTool] = useState(0);

  const handleDrawerOpen = () => {
    setOpen(true);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  }

  const FallbackLoading = () => <div></div>

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            King Manager
          </Typography>
        </Toolbar>
      </AppBar>
      <Router>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={clsx(classes.toolbar, classes.side)}>
            <img className={classes.logo} src={logo} alt="king logo" />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </div>
          <List>
            {toolList.map((tool, index) => (
              <Link className={classes.link} key={tool.title} to={tool.path}>
                <ListItem button onClick={ setActivedTool.bind(null, index) } key={tool.title}>
                  <ListItemIcon>{index === currentActivedToolIndex ? <tool.activeIcon /> : <tool.icon />}</ListItemIcon>
                  <ListItemText primary={tool.title} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>
        <div className={classes.toolbar} />
        {/* router start */}
        <Suspense fallback={FallbackLoading()}>
          <Switch>
            {router.map((route, index) => <Route
                    key={index}
                    exact={route.exact}
                    path={route.path}
                    component={lazy(() => import('../../pages/' + route.component))}
                  />
            )}
          </Switch>
        </Suspense>

        {/* router end */}
      </main>
      </Router>
      <SignOut />
      <CustomDialog />
    </div>
  );
}

export default DrawerMain
