import { createMuiTheme } from '@material-ui/core/styles';
import { PaletteOptions, PaletteColor } from '@material-ui/core/styles/createPalette';

interface CustomizedPaletteOptions extends PaletteOptions {
    success: PaletteColor
    warining: PaletteColor,
    info: PaletteColor
}

const palette: CustomizedPaletteOptions = {
  type: 'dark',
  success: {
    light: "#81c784",
    main: "#4caf50",
    dark: "#388e3c",
    contrastText: "rgba(0, 0, 0, 0.87)"
  },
  warining: {
    light: "#ffb74d",
    main: "#ff9800",
    dark: "#f57c00",
    contrastText: "rgba(0, 0, 0, 0.87)"
  },
  error: {
    light: "#e57373",
    main: "#f44336",
    dark: "#d32f2f",
    contrastText: "#fff"
  },
  info: {
    light: "#64b5f6",
    main: "#2196f3",
    dark: "#1976d2",
    contrastText: "#fff"
  },
}

const theme = createMuiTheme({
  palette
})

export default theme
