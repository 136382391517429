const router: Array<any> = [
  {
    path: "/",
    component: 'boundUsers/BoundUsers',
    exact: true
  },
  {
    path: "/instagram-users",
    component: 'instagramUsers/InstagramUsers',
    exact: true
  },
  {
    path: "/coins",
    component: 'updateCoins/UpdateCoins',
    exact: true
  }
];

export default router;
