import { randomStrWithLength, binary16ToString, key } from './util'
import CryptoJS from 'crypto-js'
import base64 from 'base-64'
import base64js from 'base64-js'

/**
 * 网络请求加密
 * @param {string} plainText 待加密文本
 * @return {string} base64 string
 */
const encodeHandler = (plainText: string): string => {
  const iv: string = randomStrWithLength(16, 'abcdefghijklmnopqrstuvwxyz0123456789')
  const hmac: string = hmacSHA256(plainText, getKey())
  const encrypt: string = base64.decode(aes256cbcEncode(plainText, getKey(), iv))
  const endText: string = iv + hmac + encrypt

  return endText
}

/**
 * 网络请求解密
 * @param {string} encrypted base64 string
 * @return {string}
 */
const decodeHandler =  (encrypted: string): string => {
  let byteEncrypted: Uint8Array = base64js.toByteArray(encrypted)
  let ivArray: Uint8Array = byteEncrypted.slice(0, 16)
  let hmacArray: Uint8Array = byteEncrypted.slice(16, 16 + 32)
  let dataArray: Uint8Array = byteEncrypted.slice(48, encrypted.length)
  let ivStr: string = base64js.fromByteArray(ivArray)
  let hmacString: string = base64js.fromByteArray(hmacArray)
      hmacString = base64.decode(hmacString)
  let dataStr: string = base64js.fromByteArray(dataArray)
  const decrypted: string = aes256cbcDecode(dataStr, getKey(), ivStr)

  return checkHmacSHA256(decrypted, hmacString) ? decrypted : ''
}

/**
 * aes256cbcEncode
 * @param {string} data 待加密文本
 * @param {string} key 自定义密钥 32字节
 * @param {string} iv 初始向量 16字节
 * @return {string} base64 string
 */
const aes256cbcEncode = (data: string, key: string, iv: string): string => {
  key = CryptoJS.enc.Utf8.parse(key)
  iv = CryptoJS.enc.Utf8.parse(iv)
  
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })

  return encrypted.toString()
}

/**
 * aes256cbcDecode
 * @param {string} encrypted 待解密文本 base64
 * @param {string} key 自定义密钥 32字节
 * @param {string} iv 初始向量 16字节 base64
 * @return {string} utf-8 string
 */
const aes256cbcDecode = (encrypted: string, key: string, iv: string): string => {
  key = CryptoJS.enc.Utf8.parse(key)
  iv = CryptoJS.enc.Base64.parse(iv)
  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  
  return decrypted.toString(CryptoJS.enc.Utf8)
}

/**
 * 解密结果校验
 * @param {string} decrypted 已经解密的文本
 * @param {string} hmacString 校验hmac字符串
 * @return {boolean}
 */
const checkHmacSHA256 = (decrypted: string, hmacString: string): boolean => hmacSHA256(decrypted, getKey()) === hmacString

/**
 * hmacSHA256
 * @param {string} plainText 待加密文本
 * @param {string} key 密钥
 * @param {number} system 采用system进制输出结果 default 16
 * @return {string} 十进制
 */
const hmacSHA256 = (plainText: string, key: string, system: number = 16): string => {
  const hmacHex = CryptoJS.HmacSHA256(plainText, key).toString()

  return system === 10 ? hmacHex : binary16ToString(hmacHex)
}

const getKey = (): string => key

export {
  encodeHandler,
  decodeHandler,
  aes256cbcEncode,
  aes256cbcDecode
}
