import { LOGIN_STATUS, DIALOG, SHOULD_REFRESH_USER_TABLE, CUSTOMIZED_SNACK_BAR } from './actionType'
import IState from '../interfaces/IState'

const initState: IState = {
	loginStatus: false,
	dialog: {
		open: false,
		leftText: '',
		rightText: '',
		title: '',
		content: '',
		close: () => {},
		continue: () => {},
	},
	shouldRefreshTable: false,
	snackBar: {
		open: false
	}
}

export default (state = initState, action:{type: string, value: any}) => {
  let newState = JSON.parse(JSON.stringify(state))
  
	switch (action.type) {
		case LOGIN_STATUS:
			newState.loginStatus = action.value
			return newState
		case DIALOG:
			newState.dialog = action.value
			return newState
		case SHOULD_REFRESH_USER_TABLE:
			newState.shouldRefreshTable = action.value
			return newState
		case CUSTOMIZED_SNACK_BAR:
			newState.snackBar = action.value
			return newState
		default:
			return state
	}
}
