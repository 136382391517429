import React, { useState } from 'react'
import { Paper, FormControl, TextField, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../images/logo.jpg'
import { setLoginStatus, setCustomizedSnackBar } from '../../store/actionCreater'
import {connect} from 'react-redux'
import { post } from '../../utils/axios'
import { USER_LOGIN } from '../../utils/api'
import constants from '../../utils/constants';
import ICustomizedSnackBars from '../../interfaces/ICustomizedSnackBar'

const useStyles = makeStyles(theme => ({
  paperContainer: {
    position: 'relative',
    boxSizing: 'border-box',
    padding: theme.spacing(10),
    maxWidth: 800,
    margin: 'auto',
    marginTop: 100,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(10),
    }
  },
  logo: {
    position: 'absolute',
    width: 100,
    left: '50%',
    top: -50,
    transform: 'translateX(-50%)',
    borderRadius: '50%',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)'
  },
  pasword: {
    marginTop: 30,
    marginBottom: 50,
  },
  buttonProgress: {
    color: '#4caf50',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  }
}))

const SignIn = (props: any) => {
  const classes = useStyles()

  const [loading, setLoading] = React.useState(false);
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [validation, setValidation] = useState({
    username: {
      error: false,
      helperText: '',
    },
    password: {
      error: false,
      helperText: '',
    },
  })

  const signIn = async () => {
    if (!username) {
      setValidation(
        {
          ...validation,
          username: {
            error: true,
            helperText: 'empty username',
          }
        }
      )
      return
    }

    if (!password) {
      setValidation(
        {
          ...validation,
          password: {
            error: true,
            helperText: 'empty password',
          }
        }
      )
      return
    }
    setLoading(true)
    const requestParams = {
      ...constants.requestBaseParams,
      admin: {
        username,
        password
      }
    }

    try {
      const result:any = await post(USER_LOGIN, requestParams)

      if (result.admin) {
        localStorage.setItem('admin', JSON.stringify(result.admin))
        props.setLoginStatus(true)
        props.setCustomizedSnackBar({
          open: true,
          type: 'success',
          title: 'Login Successful',
          body: 'Welcome user ' + username
        })
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      props.setCustomizedSnackBar({
        open: true,
        type: 'error',
        title: 'Login Failed',
        body: e
      })
    }
  }

  const clearError = () => {
    setValidation({
      ...validation,
      username: {
        error: false,
        helperText: '',
      },
      password: {
        error: false,
        helperText: '',
      },
    })
  }

  return <Paper className={classes.paperContainer}>
      <img className={classes.logo} src={logo} alt="king logo" />
      <form>
        <FormControl fullWidth>
          <TextField
            autoComplete=""
            label="User Name"
            variant="outlined"
            value={username}
            onChange={e => setUserName(e.target.value)}
            onKeyUp={e => e.keyCode === 13 && signIn()}
            onFocus={clearError}
            error={validation.username.error}
            helperText={validation.username.helperText}
          />
          <TextField
            autoComplete=""
            className={classes.pasword}
            label="Password"
            variant="outlined"
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyUp={e => e.keyCode === 13 && signIn()}
            onFocus={clearError}
            type="password"
            error={validation.password.error}
            helperText={validation.password.helperText}
          />
          <div className={classes.wrapper}>
            <Button variant="contained" color="primary" onClick={signIn} fullWidth>
              Sign In
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </FormControl>
      </form>
    </Paper>
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoginStatus(value: boolean) {
      dispatch(setLoginStatus(value))
    },
    setCustomizedSnackBar(value: ICustomizedSnackBars) {
      dispatch(setCustomizedSnackBar(value))
    }
  }
}

export default connect(null, mapDispatchToProps)(SignIn)