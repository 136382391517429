const kandongnjiushule: string = 'kandongnjiushule'

const key: string = 'a1MndD3MQRdQNLFLSpW9zdYUwb5CmMgo'

/**
 * 随机截取指定长度的字符串
 * @param {number} len 为截取字符串长度
 * @param {string} letters 要截取的字符串模板，默认为 abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ
 */
const randomStrWithLength = (len: number, letters: string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'): string => {
  let str = ''

  for (let i = 0; i < len; i++) {
    let start = Math.floor(Math.random() * Math.floor(len))
    str = str + letters.substring(start, start + 1)
  }

  return str
}

/**
 * @description 16进制 => 10进制 => 字符
 * @param {string} str Hex
 * @return {string}
 */
const binary16ToString = (str: string): string => {
  let arr: number[] = []
  
  Array.prototype.map.call(str, (c, i) => {
    if (i % 2 !== 0) {
      arr.push(parseInt(str[i - 1] + str[i], 16)) // 返回当前位置前两个
    } else if (str.length - 1 === i) {
      arr.push(parseInt(str[i], 16)) // 最后一位为偶数
    }
  })
  
  return String.fromCharCode.apply(null, arr)
}

export {
  kandongnjiushule,
  key,
  randomStrWithLength,
  binary16ToString
}
