import Axios, { AxiosRequestConfig } from "axios";
import base64 from 'base-64'
import { HOST } from "./api";
import { encodeHandler, decodeHandler } from "./encryption";

const axiosConfig: AxiosRequestConfig = {
  baseURL: HOST,
  timeout: 60000,
  validateStatus: status => {
    return status >= 200 && status < 600;
  },
  headers: {
    "X-Cipher": 1
  }
};

const axios = Axios.create(axiosConfig);

const get = (url: string) => new Promise((resolve, reject) => {
  axios
    .get(url)
    .then((response: any) => {
      if (response.status === 200) {
        const data = JSON.parse(decodeHandler(response.data))

        if (data.status) {
          resolve(data);
        } else {
          if (data.re_login) {
            localStorage.setItem('admin', '')
          }
          reject(data.message)
        }
      } else {
        reject(response.statusText);
      }
    })
    .catch((error: any) => reject(error));
})

const post = (url: string, data: Object) => new Promise((resolve, reject) => {
  data = data && base64.encode(encodeHandler(JSON.stringify(data)))

  axios
    .post(url, data)
    .then((response: any) => {
      if (response.status === 200) {
        const data = JSON.parse(decodeHandler(response.data))

        if (data.status) {
          resolve(data);
        } else if (data.re_login) {
          localStorage.setItem('admin', '')
          resolve(false)
        }
         else {
          reject(data.message)
        }
      } else {
        reject(response.statusText);
      }
    })
    .catch((error: any) => reject(error));
})

export { get, post };