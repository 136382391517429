import React from 'react'
import { Fab } from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles';
import { ExitToApp } from '@material-ui/icons';
import { setLoginStatus, setDialog } from '../../store/actionCreater';
import {connect} from 'react-redux'
import ISetDialog from '../../interfaces/ISetDialog';

const useStyles = makeStyles(theme => ({
  signOut: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  }
}))

const SignOut =  (props: any) =>  {
  const classes = useStyles()

  const signOut = (open: boolean) => {
    props.setDialog({
      open,
      leftText: 'cancel',
      rightText: 'ok',
      title: 'Tip',
      content: 'You will be logged out !',
      close: () => props.setDialog({open: false, leftText: 'cancel', rightText: 'ok', title: 'Tip', content: 'You will be logged out !', close: () => {}, continue: () => {} }),
      continue: () => {
        props.setLogin(false)
        localStorage.setItem('admin', '')
      }
    })
  }
  
  return <Fab aria-label="signOut" className={classes.signOut} color="primary" onClick={signOut.bind(null, true)}><ExitToApp /></Fab>
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLogin(value: boolean) {
      dispatch(setLoginStatus(value))
    },
    setDialog(value: ISetDialog) {
      dispatch(setDialog(value))
    },
  }
}

export default connect(null, mapDispatchToProps)(SignOut)