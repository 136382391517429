import { LOGIN_STATUS, DIALOG, SHOULD_REFRESH_USER_TABLE, CUSTOMIZED_SNACK_BAR } from './actionType'
import ISetDialog from '../interfaces/ISetDialog'
import ICustomizedSnackBars from '../interfaces/ICustomizedSnackBar'

export const setLoginStatus = (value: boolean) => ({
	type: LOGIN_STATUS,
	value
})

export const setDialog = (value: ISetDialog) => ({
	type: DIALOG,
	value
})

export const setShouldRefreshUserTable = (value: boolean) => ({
	type: SHOULD_REFRESH_USER_TABLE,
	value
})

export const setCustomizedSnackBar = (value: ICustomizedSnackBars) => ({
	type: CUSTOMIZED_SNACK_BAR,
	value
})
