import config from './config'

export default {
  requestBaseParams: {
    app: {
      bundle_id: config.bundle_id,
      app_version: config.app_version,
      device_id: config.device_id
    }
  },
  appList: [
    {appName: 'Super Portrait Camera', bundleId: 'com.yitingwang.portrait.cam'},
    {appName: 'Pop Liked Stickers for Photos', bundleId: 'com.mobileApp.photoEditor'},
    {appName: 'Get Likes Collage', bundleId: 'com.like.photocollage'}
  ]
}