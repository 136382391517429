import React, { useLayoutEffect } from 'react';
import { Container, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme/theme';
import DrawerMain from './components/drawerMain/DrawerMain'
import SignIn from './pages/singIn/SignIn';
import {connect} from 'react-redux'
import { setLoginStatus } from './store/actionCreater';
import CustomizedSnackbars from './components/customizedSnackbars/CustomizedSnackbars';

const App: React.FC = (props: any) => {

  const verifyState = () => {
    const admin: string | null = localStorage.getItem('admin')
    props.setLoginStatus(Boolean(admin && Boolean(JSON.parse(admin)?.access_token)))
  }

  useLayoutEffect(() => {
    verifyState()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <div className="App">
      <ThemeProvider theme={ theme }>
        <CssBaseline />
        <Container>
          {props.loginStatus ? <DrawerMain /> : <SignIn /> }
        </Container>

        <CustomizedSnackbars />
      </ThemeProvider>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  loginStatus: state.loginStatus
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoginStatus(value: boolean) {
      dispatch(setLoginStatus(value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
