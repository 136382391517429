const DEV_HOST = "https://king-admin.igpro.net";
// const PROD_HOST = "";

const HOST = DEV_HOST;
const ADD_USER = "/user/bind";
const REMOVE_USER = "/user/unbind";
const USER_LOGIN = "/admin/user/login";
const USER_LIST = "/user/bind/list";
const IG_USER_INFO = "/ig/user/info";
const UPDATE_COINS = "/ig/user/coins/add";

export { HOST, ADD_USER, REMOVE_USER, USER_LOGIN, USER_LIST, IG_USER_INFO, UPDATE_COINS };
