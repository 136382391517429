import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { setCustomizedSnackBar } from '../../store/actionCreater';
import { connect } from 'react-redux';
import ICustomizedSnackBars from '../../interfaces/ICustomizedSnackBar';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function CustomizedSnackbars(props: any) {
  const classes = useStyles();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    props.setCustomizedSnackBar({
      ...props.snackBar,
      open: false
    })
  };

  return (
    <div className={classes.root}>
      <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={props.snackBar.open} autoHideDuration={props.snackBar.autoHideDuration || 6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.snackBar.type}>
          {props.snackBar.title && <AlertTitle>{props.snackBar.title}</AlertTitle>}
          {props.snackBar.body}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  snackBar: state.snackBar
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCustomizedSnackBar(value: ICustomizedSnackBars) {
      dispatch(setCustomizedSnackBar(value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomizedSnackbars)
